<template>
    <div>
        <p class="text-orange">
            PS：已发起变更的结算单(正在审批中或者是草稿状态)，将不在当前列表内显示，请到变更列表中编辑查看！
        </p>
        <Row class="p-t-10">
            <i-col span="6">选择变更结算单：</i-col>
            <i-col span="18">
                <i-select v-model="chosseStatementId" filterable clearable 变更结算单>
                    <i-option v-for="statement in list" :key="statement.id" :value="statement.id">{{statement.name}}</i-option>
                </i-select>
            </i-col>
        </Row>
        <div class="p-t-10" v-if="chosseStatementId && chosseStatementId!==0">
            <Row>
                <i-col span="6">结算单编号：</i-col>
                <i-col span="18">{{statement.code}}</i-col>
            </Row>
            <Row>
                <i-col span="6">客户：</i-col>
                <i-col span="18">{{statement.advertiserName}}</i-col>
            </Row>
            <Row>
                <i-col span="6">总额：</i-col>
                <i-col span="18">{{formatMoney(statement.amount)}}</i-col>
            </Row>
            <Row>
                <i-col span="6">结算单开始日期：</i-col>
                <i-col span="18">{{statement.startDate}}</i-col>
            </Row>
            <Row>
                <i-col span="6">结算单结束日期：</i-col>
                <i-col span="18">{{statement.endDate}}</i-col>
            </Row>
        </div>
        <Row class="text-right p-t-10">
          <Button type="success" v-show="!isSubmit" @click="handleCreateContractChange">创建结算单变更</Button>
        </Row>
    </div>
</template>

<script>
import { getStatementPage } from '@/api/scp/statement'
import { getStatementChangingVersionId } from '@/api/scp/statementChange'
import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      list: [],
      query: { pageNumber: 1, pageSize: 100 },
      chosseStatementId: 0,
      statement: {}
    }
  },
  methods: {
    getPage () {
      this.query.userId = this.$store.getters.token.userInfo.userId
      this.query.statusList = JSON.stringify([5])
      this.query.isChanging = false

      getStatementPage(this.query).then(res => {
        this.list = res.list
      })
    },
    handleCreateContractChange () {
      getStatementChangingVersionId({ statementId: this.chosseStatementId }).then(res => {
        if (res && !res.errcode) {
          this.chosseStatementId = 0
          this.getPage()
          this.$Notice.success({ desc: '结算单变更创建成功！' })
          this.$emit('on-create', { id: res.statementId, versionId: res.versionId })
        }
      })
    },
    formatMoney (money) {
      return toMoney(money || 0)
    }
  },
  computed: {
    isSubmit () {
      return this.chosseStatementId === 0 || this.chosseStatementId === undefined
    },
    updateTime () {
      return this.$store.state.contract.beginUpdate
    }
  },
  created () {
    this.getPage()
  },
  watch: {
    chosseStatementId (val) {
      if (val && val !== 0) {
        this.statement = this.list.find(x => x.id === val)
      } else {
        this.statement = {}
      }
    },
    updateTime () {
      this.getPage()
    }
  }
}
</script>
